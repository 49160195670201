import Link from 'next/link'
import { useRouter } from 'next/router'
import { connect } from 'react-redux'

import { translate } from '@app/locale/translate'
import Template from '@app/components/template'
import ReactHtmlParser from 'react-html-parser'
import { Flex, Heading, Text, Button } from 'rebass'

import packageJson from '@app/package.json';

const LandingPage = ({ user, city }) => {
  // console.log('build version: ', packageJson.version)

  const router = useRouter()
  if(user && user.curr) {
    router.push('/dashboard')
  }

  if(!city) {
    return <Template/>
  }

  return (
    <Template>
      <Flex
        mx={'auto'}
        width={[1, 2 / 3, 1 / 2]}
        maxWidth={1080}
        flexWrap='wrap'
        justifyContent='center'
        sx={{
          textAlign: 'center'
        }}
      >
        <Heading
          color="accent"
          fontSize={[3, 4, 5]}
          width={1}
          my={16}
        >{translate("Welcome to the<br/> %(festival_name)s Matching Site", {'festival_name': city ? city.festival_name : 'Make Music Day'})}
        </Heading>

        <Text
          my={8}
          width={1}
          fontSize={[1, 2, 3]}
          style={{
            whiteSpace: 'pre-line'
          }}
        >{ReactHtmlParser(city.welcome_blurb)}</Text>

        {city.is_paused && (
          <Text
            my={8}
            color="accent"
            width={1}
            fontSize={[1, 2, 3]}
            style={{
              whiteSpace: "pre-line",
            }}
          >
            {ReactHtmlParser(city.paused_message)}
          </Text>
        )}

        {city.festival.is_launched && !city.is_paused && <Link href={user ? "/welcome" : "/account/create"}>
          <Button my={16}>{translate("Create Account")}</Button>
        </Link>}
      </Flex>
    </Template>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
  city: state.city
})


export default connect(mapStateToProps)(LandingPage)
